import axios from '@/api/api-request'

const projectUrl = '/webTopic'

// 品牌荣誉-获取实体信息
export const getInfo = (params) => {
  return axios.request({
    url: projectUrl + '/getInfo.do',
    method: 'post',
    params: params
  })
}
export const getLists = (data) => {
  return axios.request({
    url: projectUrl + 'getLists.do',
    method: 'post',
    data: data
  })
}
// 品牌荣誉-获取分页列表
export const topicList = (data) => {
  return axios.request({
    url: projectUrl + '/topicList.do',
    method: 'post',
    data: data
  })
}
// 品牌荣誉-首页显示 获取分页列表
export const firstGetLists = (data) => {
  return axios.request({
    url: projectUrl + '/firstGetLists.do',
    method: 'post',
    data: data
  })
}
