<template>
  <div class="kuang_ping_nwes">
    <div class="news_xianq_k px1200">
      <div class="dqwz_S"> 当前位置：<my-link href="/home-layout">首页</my-link> > <my-link href="/topic">品牌荣誉</my-link> > 详情</div>
      <div class="neor">
        <div class="biaot">
          <h3>{{topic.title}}</h3>
          <div class="xia_sd">
            <span>{{ topic.createTime|timestampToTime }}</span>
            <!--            <span>422</span>-->
            <!--            <span>信息来源：先行者</span>-->
            <div class="bshare-custom" style="display: inline-block;">
              <div class="bsPromo bsPromo2"></div>
            </div>
          </div>
        </div>
        <div class="text" v-for="item in topic.webTopicDetailLists" :key="item.id">
          <h4 v-if="item.title !== '无标题' && item.title !== '默认标题'">{{item.title}}</h4>
          <div v-html="item.content"></div>
        </div>
        <!--        <div class="fanye">-->
        <!--          <p> 上一篇： <a href="#">帘上的空</a></p>-->
        <!--          <p> 下一篇： <a href="#">岁月的倒影也将消失</a></p>-->
        <!--        </div>-->
      </div>
    </div>
  </div>
<!--  <div class="wrap-lg">-->
<!--    <el-breadcrumb separator-class="el-icon-arrow-right" style="padding: 20px 0">-->
<!--      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item :to="{ path: '/topic' }">品牌荣誉</el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>{{topic.title}}</el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->
<!--    <el-card style="background: #ccc0; border: 1px solid #ffffff50;">-->
<!--      <el-row :gutter="20">-->
<!--        <el-col :span="24" class="reach-html">-->
<!--          <h2>{{topic.title}}</h2>-->
<!--          <div v-for="item in topic.webTopicDetailLists" :key="item.id">-->
<!--            <h4 v-if="item.title !== '无标题' && item.title !== '默认标题'">{{item.title}}</h4>-->
<!--            <div v-html="item.content"></div>-->
<!--          </div>-->
<!--        </el-col>-->
<!--      </el-row>-->
<!--    </el-card>-->
<!--  </div>-->
</template>

<script>
import { getInfo } from '@/api/platform/topic'

export default {
  name: 'detail',
  data () {
    return {
      topicId: null,
      topic: {
      }
    }
  },
  mounted () {
    this.topicId = this.$route.query.sid
    this.getInfo()
  },
  methods: {
    toPage (path) {
      this.$router.push({
        name: path,
        service: {}
      })
    },
    getInfo () {
      let param = {}
      param.id = this.topicId
      getInfo(param).then(res => {
        this.loading = false
        var result = res.data
        if (result.rtnCode !== '0') {
          this.$message({
            type: 'info',
            message: result.msg
          })
        } else {
          this.topic = result.bizData
        }
      }).catch(ex => {
        this.loading = false
        this.$message.error('列表加载失败，请稍后再试！')
      })
    }
  }
}
</script>

<style scoped>
/deep/.el-breadcrumb__inner.is-link, .el-breadcrumb__inner a {
  color: #4f080e;
}
  h4{
    border-left: solid 6px #4f080e;
    padding-left: 10px;
  }
</style>
